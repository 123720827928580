import React from "react";
import img1 from "../../img/slider/asansorlu-nakliyat.jpg";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
function Asansorlu() {
  return (
    <div className="sm:max-w-screen-sm mx-auto md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg">
      <Helmet>
        <title>ÖzFilo - Asansörlü Eşya Taşıma </title>
        <meta
          name="description"
          content="ÖzFilo nakliyat olarak evden eve asansörlü taşımacılıkta bir numarayız. Sigortalı, hızlı ve güvenilir taşımacılık hizmeti sunuyoruz. 0532 362 00 16"
        />
        <link
          rel="canonical"
          href="https://www.ozfilonakliyat.com/asansorlu-esya-tasima"
        />
      </Helmet>
      <h1 className="text-5xl text-center mt-10">
        ÖzFilo - Asansörlü Eşya Taşıma
      </h1>
      <img
        src={img1}
        alt="ÖzFilo - Asansörlü Eşya Taşıma"
        className="mx-auto w-[800px] my-10"
      />
      <div className="text-gray-600 p-5 flex flex-col space-y-10 my-10">
        <div className="flex flex-col space-y-5">
          <p>
            <strong>Asansörlü Evden Eve Nakliyat, Asansörlü Eşya Taşıma</strong>{" "}
            konusunda Özfilo Nakliyat olarak İstanbul da tüm ilçe ve semtlere
            yıllardır Platform Asanasörlü araçlarımızla hizmet veriyoruz.
            Kaliteli ve güvenli bir{" "}
            <em>
              <strong>asansörlü nakliyat</strong>
            </em>{" "}
            için bizim ile iletişime geçmeniz yeterlidir. Tüm Nakliye hizmetleri
            gibi <strong>Asansörlü Nakliyat</strong> konusunda da iddialıyız.
            Dış cephe platform asansörlerimiz son teknoloji üretimi olan
            araçlardır. Bu araçlarımız garantili ve sağlıklı bir taşıma için
            çokça önerilen bir taşıma sistemidir. Asla eşyalarınıza zarar
            vermeden taşıma işleminiz gerçekleşmektedir. Bu hizmetlerimiz
            İstanbul genelinde sıkça ihtiyaç duyulan bir hizmettir. Hizmetin
            öncü firması olan Özfilo Taşımacılık sizlerin emrindedir.
          </p>
          <p>
            <strong>Asansörlü eşya taşıma </strong>taşınma işlemlerinde
            özellikle yüksek katlı binalarda en çok tercih edilen eşya taşıma
            yöntemidir. Yüksek katlı binalardan eşya indirmek ya da çıkarmak,
            güç kullanılarak yapıldığı zaman uzun sürer ve taşınma işleminiz
            uzayabilir. Bina içinde eşya asansörü bulunmazsa, eşyanın taşınması
            merdiven kullanılarak yapılacağından, eşyaların zarar görmesi söz
            konusu olabilmektedir.
          </p>
          <p>
            <strong>Özfilo evden eve nakliyat </strong>firmamız aracılığıyla,
            yüksek katlarda yer alan evleriniz için eşya taşıma işlemini
            kolaylaştırıyoruz. A<strong>sansörlü eşya taşıma</strong> hizmetimiz
            ile taşıma işlemleri hızlı şekilde ilerlemektedir. Hizmetimizden
            yararlanabilmeniz için bize ulaşmanız ve randevu kaydı oluşturmanız
            gerekmektedir. Asansörlerimiz için kayıt oluşturmadan önce binanızın
            asansörlü taşıma yapmaya uygun olduğuna emin olmanız
            gereklidir.&nbsp; &nbsp;Asansörlü taşıma hizmetimize ek olarak&nbsp;
            <strong>vinçle eşya taşıma, evden eve vinç, asansörlü vinç</strong>
            &nbsp;hizmetlerimizde bulunmaktadır. Özfilo Evden Eve Nakliyat
            firması olarak güvenli bir şekilde&nbsp;
            <strong>asansörlü evden eve nakliyat</strong>&nbsp;işlemlerinizi
            yapmaktan mutluluk duyarız. En uygun fiyatlı hizmet alabilmek için
            lütfen bizimle iletişime geçin.
          </p>
        </div>
        <div className="flex flex-col md:flex-row ">
          <NavLink
            to={"tel:05323620016"}
            title="özfilo - iletişim"
            className="flex items-center lg:w-2/4 xl:w-1/4 mx-auto my-5 justify-center group hover:text-blue-700 hover:border-blue-700 hover:border-2 gap-3 p-3 border border-gray-600 text-gray-600 text-3xl"
          >
            <svg
              aria-hidden="true"
              className="fill-gray-600 group-hover:fill-blue-700"
              width="30px"
              height="30px"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
            </svg>
            0532 362 0016
          </NavLink>
          <NavLink
            to={
              "https://wa.me/905323508851?text=Merhaba%20Nakliye%20Hakk%C4%B1nda%20Bilgi%20Almak%20%C4%B0stiyorum"
            }
            title="özfilo - iletişim"
            className="flex my-5 bg-[#25D366] lg:w-2/4 xl:w-1/4 mx-auto items-center justify-center group  hover:border-black hover:border-2 gap-3 p-3 border border-black text-white text-3xl"
          >
            <svg
              aria-hidden="true"
              className="fill-white"
              width="30px"
              height="30px"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
            </svg>
            0532 350 88 51
          </NavLink>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">
            Asansörlü Evden Eve Nakliyat
          </h2>
          <div className="flex flex-col space-y-5">
            <p>
              <strong>Asansörlü eşya taşıma </strong>hizmeti sunan firmamız,
              nakliyat alanında yılların verdiği bilgi, birikim ve tecrübe ile
              çalışmasını sürdürmektedir. Eşyalarınız, asansörlerimiz yardımı
              ile yüksek katlı binalara rahatlıkla çıkarılır veya indirilmesi
              sağlanır. Asansörlerimizi kullanan operatörlerimiz, işinde oldukça
              başarılı ve tecrübe sahibi kişilerdir. Bilgili ve tecrübeli
              olmaları nedeni ile eşyalarınızın nakliyesi sorunsuz olarak
              gerçekleştirilmektedir.
            </p>
            <p>
              <strong>Asansörlü eşya taşıma </strong>sisteminin sağlamış olduğu
              kolaylık nedeni ile eşyalarınızın taşınması güvenli bir şekilde
              gerçekleşmektedir. Asansör sistemlerimiz, taşıması
              gerçekleştirilecek eşyaların ebat büyüklüğüne göre
              belirlenmektedir. Eşyalarınızın büyüklüğüne, ağırlığına
              bakılmaksızın, asansör sistemimiz aracılığıyla güvenli şekilde ve
              kısa sürede taşıma işlemi gerçekleştirilmekte ve nakliye
              araçlarımıza ağır eşyalar altta kalacak şekilde konularak
              nakliyesi gerçekleştirilmektedir.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">Asansörlü Taşımacılık</h2>
          <div className="flex flex-col space-y-5">
            <p>
              <strong>Asansörlü eşya taşıma </strong>sistemi, diğer yöntemlere
              göre oldukça kullanışlı ve son derece güvenilirdir. Eşyaların tek
              tek çıkarılması uzun zaman alır ve bununla birlikte, kolilere
              konulan hassas eşyaların merdivenlerden taşınması sırasında
              kırılma ihtimali söz konusu olabilmektedir. Merdivenlere zorlukla
              sığan eşyalar, taşıma sırasında çarpma vurma gibi istenmeyen
              olaylara maruz kalarak hasar görebilmektedir.
            </p>
            <p>
              Bu durumun önüne geçmek, taşıma işlemini hızlandırmak ve eşyaların
              zarar görmesini engellemek için kullanılması önerilen yöntem
              asansörle taşıma sistemidir.
            </p>
            <p>
              Ev taşıma işlemlerinde <strong>asansörlü eşya taşıma</strong>{" "}
              sisteminin kullanılabilmesi için birtakım şartlar mevcuttur ve bu
              şartlar arasında, eşyası taşınılmak istenen evin 3. kat ve
              üzerinde yer almasının gerekli oluşu bulunmaktadır. Bu şartın
              konulma nedeni ise merdivenin açılım sağladığı zaman uzunluğunun 8
              ile 12 metre gibi bir uzunluğa sahip olması nedeni iledir.
            </p>
            <p>
              Nakliyenin sağlanabilmesi için{" "}
              <strong>asansörlü eşya taşıma </strong>sisteminin kurulumu çift
              açılımı olan pencere ve balkonlara yapılabilmektedir. Balkon
              kapısından geçirilebilen tüm eşyaların, asansör kullanılarak
              taşınması mümkün olabilmektedir. Balkon kapılarının dar olduğu ve
              büyük ebatlı ürünlerin balkon kapısından geçemeyeceği
              unutulmamalıdır. Çift açılım cama kurulması durumunda tüm
              eşyaların asansörle taşınması daha kolay hale gelmektedir.
            </p>
            <p>
              Çift açılım cama sahip olunmaması ve de balkon bulunmaması
              durumunda ise evde nakliye için en uygun pencere nakliye işleminin
              gerçekleştirilebilmesi için sökülebilir ve iş bitiminde yerine
              monte edilebilmektedir. Nakliye yapılacağı gün hava şartlarının
              iyi olmasına dikkat edilmesi gerekmektedir.&nbsp;
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">
            Vinçle Eşya Taşıma Fiyatları
          </h2>
          <div className="flex flex-col space-y-5">
            <div class="elementor-widget-container">
              <p>
                <strong>Vinçle eşya taşıma hizmeti</strong> de sunan firmamız{" "}
                <em>
                  evden eve vinç, asansörlü vinç, vinçle eşya taşıma, vinçle ev
                  taşıma ve vinçli nakliyat
                </em>{" "}
                gibi bir çok talebi karşılayabilmektedir.{" "}
                <strong>Vinçli eşya taşıma</strong> hizmeti için kullanılmakta
                olan asansörlerin fiyatları nakliye ücretine ek olarak 350 ile
                600 TL arasında bulunabilmektedir ve fiyatlar il ve ilçeye göre
                farklılık gösterebilir.
              </p>{" "}
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">
            Asansörlü Nakliyat Fiyatları
          </h2>
          <div className="flex flex-col space-y-5">
            <div class="elementor-widget-container">
              <p>
                Eşya taşımanın kolay ve güvenli yöntemi olan{" "}
                <strong>asansörlü nakliyat, asansörlü taşıma </strong>sisteminin
                sağlamış olduğu avantajlarla eşyalarınızın kısa sürede taşınması
                sağlanarak, nakliye gerçekleştirilir. Asansör sistemi
                kullanılarak taşınma işlemi gerçekleştirildiğinde ücret, nakliye
                fiyatına ek olarak yansıtılmaktadır.{" "}
                <strong>Asansörlü nakliyat</strong> hizmeti için fiyat
                belirlenirken, eşyanın taşınma süresi, kullanılan yakıt, mesafe
                gibi etkenler fiyatları belirleyici niteliktedir.
              </p>
              <p>
                Asansörlerimizi belirli saate göre kiralayabilmenin yanı sıra
                günlük, haftalık, aylık ya da ihtiyacınıza göre kiralayabilme
                imkanına da sahipsiniz. <strong>Asansörlü eşya taşıma </strong>
                sisteminin sağlamış olduğu avantajlarla, taşıma işlemleri
                sorunsuz ve kısa zaman içerisinde gerçekleştirilmektedir.
                Asansörlerimizi, istediğinize uygun olacak şekilde ve
                istediğiniz yerde kullanma imkanı{" "}
                <strong>Özfilo evden eve nakliyat</strong> firmamız aracılığıyla
                sağlanmaktadır.
              </p>{" "}
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-black text-4xl my-5">Asansörlü Nakliyat</h2>
          <div className="flex flex-col space-y-5">
            <p>
              Güvenle kullanılan yöntemler arasında yer alan asansörlü nakliyat
              hizmetimiz hakkında detaylı bilgilere
              <strong> ozfilonakliyat.com </strong>internet adresinden
              ulaşabilirsiniz. Asansör sisteminin sağlamış olduğu kolaylık
              sebebiyle, taşıma işlemlerinde tercih edilme oranı giderek
              artmaktadır. <strong>Asansörlü nakliyat </strong>hizmetimizden
              faydalanmak ve nakliyat işlemlerinizi kısa zamanda gerçekleştirmek
              için 7/24 bize ulaşabilir, hizmetlerimizden faydalanabilmek için
              randevu kaydı oluşturabilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Asansorlu;
