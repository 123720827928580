import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaMagnifyingGlassLocation } from "react-icons/fa6";

function NotFoundPage() {
  let location = useLocation();

  return (
    <div className=" w-full h-[100vh] flex items-center justify-center text-[3vw]">
      <div className=" flex items-start flex-col">
        <FaMagnifyingGlassLocation className=" mx-auto" size={150} />
        <h1 className=" text-[8vw]">404</h1>
        <h1>Ooops! Page Not Found</h1>
        <code>{location.pathname}</code>
        <NavLink
          to={"/"}
          className="bg-success text-white p-3 rounded-lg w-full text-center"
        >
          Ana sayfa
        </NavLink>
      </div>
    </div>
  );
}

export default NotFoundPage;
