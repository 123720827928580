import React from "react";
import img1 from "../../img/Hizmetler/komple-bina-tasima.jpg";
import { Helmet } from "react-helmet-async";
function BinaNakliyat() {
  return (
    <div className="sm:max-w-screen-sm mx-auto md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg">
      <Helmet>
        <title>ÖzFilo - Bina Taşıma </title>
        <meta
          name="description"
          content="ÖzFilo nakliyat olarak komple bina taşıma hizmeti sunuyoruz. Bina nakliyatı İstanbul'un her yerinde yapılmaktadır. Bina nakliyatı için bizimle iletişime geçin."
        />
        <link
          rel="canonical"
          href="https://www.ozfilonakliyat.com/komple-bina-tasima"
        />
      </Helmet>
      <h1 className="text-5xl text-center mt-10">
        ÖzFilo - Kompe Bina Nakliyatı
      </h1>
      <img
        src={img1}
        alt="ÖzFilo - Kompe Bina Nakliyatı"
        className="mx-auto w-[800px] my-10"
      />
      <div className="text-gray-600 p-10 flex flex-col space-y-10 my-10">
        <p>
          Bina Nakliyatı İstanbuldaki kentsel dönüşümden dolayı sıkça ihtiyaç
          bulunan bir hizmettir. Bütün İstanbul ilçe ve semtlerde bina nakliyatı
          hizmetimiz mevcuttur. Dolayısı ile tüm binaların boşaltılmasını bir
          gün içinde yani 24 saatlik zaman diliminde tamamlayabiliyoruz. Dış
          cephe asansörlerimi ile zamandan yeterince süre kazanıyoruz. Öncelikle
          bu hizmeti almanız için bizim ile iletişime geçip sözleşme
          hazırlamanız lazım. Daha sonra yapılacak iş programı ile binayı bize
          teslim etmeniz gerekiyor. Bu işlemler tamamlandıktan sonra bizler
          hemen işe başlıyoruz ve 24 saat zaman diliminde binayı komple
          boşaltıyoruz. Ve eşyalarınızın diğer adreslere sağlıklı taşınmasını
          sağlıyoruz.
        </p>
      </div>
    </div>
  );
}

export default BinaNakliyat;
