import React from "react";
import blog1 from "../img/Blog/kartal-evden-eve-nakliyat-768x480.jpg";
import blog2 from "../img/Blog/kartal-sehir-ici-esya-tasima.jpg";
import blog3 from "../img/Blog/markus-spiske-XrIfY_4cK1w-unsplash-768x512.jpg";
import blog4 from "../img/Blog/pendik-evden-eve-nakliyat-768x480.jpg";
import blog5 from "../img/Blog/pendik-sehir-ici-nakliyat-768x480.jpg";
import blog6 from "../img/Blog/sancaktepe-sehir-ici-nakliyat-768x480.jpg";
import blog7 from "../img/Blog/sehir-disi-ev-tasima-firmasi.jpg";
import blog8 from "../img/Blog/sehir-disi-evden-eve-nakliyat-sirketi.jpg";
import blog9 from "../img/Blog/sultanbeyli-sehir-ici-nakliyat-768x480.jpg";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
function Blog() {
  return (
    <section className="px-10 lg:px-5 sm:max-w-screen-sm mx-auto md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg">
      <Helmet>
        <title>ÖzFilo - Blog </title>
        <meta
          name="description"
          content="Şehir içi ve şehirler arası nakliyat, sigortalı taşımacılık ve güvenli eşya depolama hizmetleri sunuyoruz. Profesyonel ekibimizle hızlı, güvenli ve uygun fiyatlı taşımacılık"
        />
        <link rel="canonical" href="https://www.ozfilonakliyat.com/blog" />
      </Helmet>
      <div className="text-gray-600 text-center mb-6">
        <h1 className="text-3xl ">ÖZFİLO NAKLİYAT BLOG YAZILARI</h1>
        <p>Nakliye Hakkında Önemli Bilgiler</p>
      </div>
      <div className="flex flex-col md:flex-row md:flex-wrap  items-center space-y-3  ">
        <NavLink
          to={"/sehir-disi-ev-tasima"}
          title="sehir-dışı-ev-taşıma"
          className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
        >
          <img
            src={blog3}
            alt="şehir-dışı-ev-taşıma-fiyatları"
            title="şehir dışı ev taşıma fiyatları"
            className=" rounded-t-md"
          />
          <h2 className=" text-3xl px-3">
            Şehir Dışı Ev Taşıma Fiyatları 2025
          </h2>
          <p className=" text-gray-500 px-3">
            Şehir dışı güncel ev taşıma fiyatları 2025 için de belirlenmiş
            durumdadır. Güncel hizmet fiyatlarımız hakkında bilgi almak
            isteyenler 7/24 firmamızla iletişime geçebilirler. Güler yüzlü ve
            deneyimli ekibimiz her sorunuza en doğru ve güncel şekilde cevap
            veriyor olacaktır. Nakliyat hizmetini doğru adresten alarak,
            hizmetten memnun kalmak isteyenler firmamızı her zaman tercih
            edebilirler.
          </p>
        </NavLink>
        <NavLink
          to={"/sultanbeyli-evden-eve-nakliyat"}
          title="sultanbeyli-evden-eve-nakliyat"
          className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
        >
          <img
            src={blog9}
            alt="sultan-beyli-evden-eve-nakliyat"
            title="sultan beyli evden eve nakliyat"
            className=" rounded-t-md"
          />
          <h2 className=" text-3xl px-3">Sultanbeyli Evden Eve Nakliyat</h2>
          <p className=" text-gray-500 px-3">
            İstanbul Şehir içi nakliyat Sultanbeyli hizmetlerimiz devam ediyor.
            Parça eşya taşıma, evden eve nakliyat, işyeri ve fabrika taşıma gibi
            çeşitli hizmetlerimizi sizlere sunmaktan mutluluk duyuyoruz.
            Sultanbeyli şehir içi nakliyat ihtiyaçlarınızda firmamıza iletişim
            sayfamızda yer alan numaralarımızdan ulaşabilirsiniz.
          </p>
        </NavLink>
        <NavLink
          to={"/pendik-evden-eve-nakliyat"}
          className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
        >
          <img
            src={blog5}
            alt="pendik-evden-eve-nakliyat"
            title="pendik evden eve nakliyat"
            className=" rounded-t-md"
          />
          <h2 className=" text-3xl px-3">Pendik Evden Eve Nakliyat</h2>
          <p className=" text-gray-500 px-3">
            Şehir içi nakliyat firmaları arasında sunduğu hizmet kalitesi ile
            öne çıkan Özfilo evden eve nakliyat ile tüm eşyalarınız güvenle
            taşınır. Pendik şehir içi nakliyat hizmetimiz çerçevesinde Tüm
            Pendik halkına uzman ekip ile en profesyonel hizmeti sunmaya
            hazırız.
          </p>
        </NavLink>
        <NavLink
          to={"/sancaktepe-evden-eve-nakliyat"}
          title="sancaktepe-evden-eve-nakliyat"
          className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
        >
          <img
            src={blog6}
            alt="sancaktepe-evden-eve-nakliyat"
            title="sancaktepe evden eve nakliyat"
            className=" rounded-t-md"
          />
          <h2 className=" text-3xl px-3">Sancektepe Evden Eve Nakliyat</h2>
          <p className=" text-gray-500 px-3">
            Şehir içi nakliyat firmaları arasında Sancaktepe bölgesine de hizmet
            veren firmamız tüm eşyalarınızın nakliyat işlemlerini güvenle
            yapmaktadır. Özfilo Evden Eve Nakliyat güvencesi ile Sancaktepe
            şehir içi nakliyat hizmetlerimizden faydalanmak ister misiniz? 0532
            362 0016 numaralı müşteri hizmetlerimizden bizlere ulaşabilirsiniz.
          </p>
        </NavLink>
        <NavLink
          to={"/kartal-evden-eve-nakliyat"}
          title="kartal-evden-eve-nakliyat"
          className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0 pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
        >
          <img
            src={blog2}
            alt="kartal-evden-eve-nakliyat"
            title="kartal evden eve nakliyat"
            className=" rounded-t-md"
          />
          <h2 className=" text-3xl px-3">Kartal Evden Eve Nakliyat</h2>
          <p className=" text-gray-500 px-3">
            Özfilo evden eve nakliyat, 26 yıldan uzun süredir şehirler arası ve
            şehir içi nakliyat hizmetleri sunmaktadır. Firmamız İstanbul’un her
            bir semtinde olduğu gibi Kartal şehir içi nakliyat ihtiyaçlarına da
            hızlı, güvenli ve fiyat avantajları ile yanıt vermektedir.
          </p>
        </NavLink>
        <NavLink
          to={"/pendik-evden-eve-nakliyat"}
          title="pendik-evden-eve-nakliyat"
          className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
        >
          <img
            src={blog4}
            alt="pendik-evden-eve-nakliyat"
            title="pendik evden eve nakliyat"
            className=" rounded-t-md"
          />
          <h2 className=" text-3xl px-3">Pendik Evden Eve Nakliyat</h2>
          <p className=" text-gray-500 px-3">
            Özfilo evden eve nakliyat, 26 yıldan uzun süredir şehirler arası ve
            şehir içi nakliyat hizmetleri sunmaktadır. Firmamız İstanbul’un her
            bir semtinde olduğu gibi Kartal şehir içi nakliyat ihtiyaçlarına da
            hızlı, güvenli ve fiyat avantajları ile yanıt vermektedir.
          </p>
        </NavLink>
        <NavLink
          to={"/kartal-evden-eve-nakliyat"}
          title="kartal-evden-eve-nakliyat"
          className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
        >
          <img
            src={blog1}
            alt="kartal-evden-eve-nakliyat"
            title="kartal evden eve nakliyat"
            className=" rounded-t-md"
          />
          <h2 className=" text-3xl px-3">Kartal Evden Eve Nakliyat</h2>
          <p className=" text-gray-500 px-3">
            Kartal’da ikamet eden ve gerek şehir içi gerekse de şehirlerarası
            taşınma sürecinde bulunan kişilerin en büyük ihtiyacı Kartal evden
            eve nakliyat firması olmaktadır. Bu arayışlarında en doğru evden eve
            nakliyat firması seçimi yaparken doğru karar verilmesi ve Özfilo
            evden eve nakliyat firmamız gibi kurumsal firmaların tercih edilmesi
            alınacak hizmet kalitesi açısından önemlidir.
          </p>
        </NavLink>
        <NavLink
          to={"/sehir-disi-eve-nakliyat"}
          title="şehır-dışı-evden-eve-nakliyat"
          className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
        >
          <img
            src={blog7}
            alt="sehir-dısı-evden-eve-nakliyat"
            title="şehir dışı evden eve nakliyat"
            className=" rounded-t-md"
          />
          <h2 className=" text-3xl px-3">Şehir Dışı Evden Eve Nakliyat</h2>
          <p className=" text-gray-500 px-3">
            Şehir dışı evden eve nakliyat şirketi olmasının yanında şehir içi
            hizmet de veren Özfilo Nakliyat, müşteri ayrımı göz etmeksizin
            herkese aynı kalitede nakliyat hizmeti vermektedir. Uygun fiyat
            teklifleri ile taşınma işlemini hızlı bir şekilde gerçekleştirmek
            isteyenler nakliyat firmamızla iletişime geçebilirler. Taşınma
            işleminden maksimum başarı elde etmek isteyenler için firmamız en
            doğru seçenek olacaktır.
          </p>
        </NavLink>
        <NavLink
          to={"/sehir-disi-eve-nakliyat"}
          title="sehir-dışı-ev-taşıma-firması"
          className=" border border-gray-600 rounded-md flex flex-col space-y-3 md:space-y-0  pb-5 md:h-[575px] lg:w-[300px] mx-auto w-80 md:w-[330px]"
        >
          <img
            src={blog8}
            alt="sehir-dısı-ev-tasıma-firması"
            title="şehir dışı ev taşıma firması"
            className=" rounded-t-md"
          />
          <h2 className=" text-3xl px-3">Şehir Dışı Ev Taşıma Firması</h2>
          <p className=" text-gray-500 px-3">
            Şehir dışı ev taşıma firması olmakla birlikte aynı zamanda şehirler
            arası nakliyat hizmetini farklı şekilde sunan ozfilonakliyat.com
            geçmişte olduğu gibi bugünde müşteride oluşturduğu güven duygusu ile
            en çok tercih edilen firmalardan biri olmaktadır. Müşterilerden
            gelen olumlu geri dönüşler firmamız için ilham kaynağı olmakta ve
            firmamızın daha da gelişmesi için motive edici olmaktadır. İyib ir
            nakliyat firması arayanlar firmamızı her zaman arayabilirler.
          </p>
        </NavLink>
      </div>
    </section>
  );
}

export default Blog;
