import React from "react";
import img1 from "../../img/Hizmetler/depo1.jpg";
import img2 from "../../img/Hizmetler/depo2.jpg";
import img3 from "../../img/Hizmetler/depo3.jpg";
import img4 from "../../img/Hizmetler/depo4.jpg";
import video1 from "../../assets/0308.mp4";
import video2 from "../../assets/0309.mp4";
import { NavLink } from "react-router-dom";
import Iletisim from "../../components/Iletisim";
import { Helmet } from "react-helmet-async";
function EsyaDepo() {
  return (
    <div className="sm:max-w-screen-sm mx-auto md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg">
      <Helmet>
        <title>ÖzFilo - Eşya Depolama </title>
        <meta
          name="description"
          content="ÖzFilo nakliyat olarak eşya depolama hizmeti sunuyoruz. Eşyalarınızı güvenle depolayın."
        />
        <link
          rel="canonical"
          href="https://www.ozfilonakliyat.com/esya-depolama"
        />
      </Helmet>
      <h1 className="text-5xl text-center my-10">ÖzFilo - Eşya Depolama</h1>
      <video width="1080" autoPlay muted className="mx-auto" loop>
        <source src={video1} title="ÖzFilo - Eşya Depolama" type="video/mp4" />
      </video>
      <div className="text-gray-600 p-10 flex flex-col space-y-10 my-10">
        <div className="flex flex-col space-y-5">
          <p>
            Eşyalarınızı güvenle teslim edebileceğiniz bir{" "}
            <strong>depolama hizmeti </strong>
            arıyorsanız, doğru yerdesiniz! Nakliye hizmetimizin yanı sıra,
            İstanbul’un tüm ilçelerinde modern, temiz ve güvenli depolama
            alanlarımızla sizlere profesyonel eşya depolama hizmeti sunuyoruz.
            Ev eşyalarınız, ofis malzemeleriniz ya da ticari ürünleriniz fark
            etmeksizin, her türlü eşyanız bizimle güvende. Tüm depolarımız 24
            saat güvenlik kameraları ile izlenmekte ve düzenli olarak
            denetlenmektedir.
          </p>
        </div>
        <div className="flex flex-col gap-3 md:space-y-0 lg:flex-row lg:gap-0 lg:space-x-0  flex-wrap w-full">
          <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-3 lg:w-1/2">
            <img
              src={img1}
              className="md:w-1/2"
              alt="ÖzFilo - Eşya depolama hizmeti"
            />
            <img
              src={img2}
              className="md:w-1/2"
              alt="ÖzFilo - Eşya depolama hizmeti"
            />
          </div>
          <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-3 lg:w-1/2">
            <img
              src={img3}
              className="md:w-1/2"
              alt="ÖzFilo - Eşya depolama hizmeti"
            />
            <img
              src={img4}
              className="md:w-1/2"
              alt="ÖzFilo - Eşya depolama hizmeti"
            />
          </div>
        </div>
        <Iletisim />
        <p>
          Eşyalarınız, özel olarak hazırlanmış nemden ve rutubetten korunan
          kapalı alanlarda muhafaza edilir. Depolarımızın tamamı yüksek hijyen
          standartlarına uygun şekilde temizlenmekte olup, olası yangın, su
          baskını ve diğer doğal afetlere karşı sigortalıdır. Size sadece
          eşyalarınızı teslim etmek ve dilediğiniz zaman kolayca ulaşmanın
          rahatlığını yaşamak kalır.
        </p>
        <video width="1080" autoPlay muted className="mx-auto" loop>
          <source
            src={video2}
            title="ÖzFilo - Eşya Depolama"
            type="video/mp4"
          />
        </video>
        <p>
          <strong>Depolama hizmeti</strong> bizim için müşteri memnuniyeti her
          şeyden önce gelir. Bu yüzden eşyalarınızın güvenliği kadar, size
          sunduğumuz iletişim desteğini de önemsiyoruz. Depolarımızdaki
          eşyalarınıza 7/24 erişim imkânı sunuyor ve istediğiniz zaman transfer
          taleplerinizi karşılıyoruz. Uzman ekibimizle, hem nakliye hem de
          depolama süreçlerinde sizlere profesyonel, sigortalı ve güvenilir bir
          hizmet sunmanın gururunu yaşıyoruz.
        </p>
      </div>
    </div>
  );
}

export default EsyaDepo;
