import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
function Iletisim() {
  return (
    <div className=" text-xl mt-10 text-gray-600 sm:max-w-screen-sm mx-auto md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg">
      <Helmet>
        <title>ÖzFilo - Iletişim </title>
        <meta name="description" content="ÖzFilo - İletişim sayfası" />
        <link rel="canonical" href="https://www.ozfilonakliyat.com/iletisim" />
      </Helmet>
      <h1 className="text-center text-5xl text-black my-10 ">
        Özfilo Nakliyat - İletişim
      </h1>

      <div className="p-5">
        <div className="group flex flex-col justify-around items-center w-full gap-5 my-3 border h-96 p-3 shadow-xl hover:shadow-2xl rounded-xl">
          <span className=" text-5xl">Ofis</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            height="100px"
            width="100px"
            className="fill-blue-600 group-hover:fill-blue-700 mx-auto"
          >
            <path d="m300-300 280-80 80-280-280 80-80 280Zm180-120q-25 0-42.5-17.5T420-480q0-25 17.5-42.5T480-540q25 0 42.5 17.5T540-480q0 25-17.5 42.5T480-420Zm0 340q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Zm0-320Z" />
          </svg>
          <p>
            Merkez:: Bağlarbaşı Mahallesi Bağdat Caddesi Doğanlar Pasajı
            No.459/17 Maltepe / İstanbul
          </p>
        </div>

        <div className="lg:grid lg:grid-cols-3 lg:gap-3 text-4xl text-center">
          <NavLink
            className="group flex flex-col justify-around items-center w-full gap-5 my-3 border h-96 p-3 shadow-xl hover:shadow-2xl rounded-xl"
            to={"tel:02164271783"}
          >
            Hemen Bize Ulaşın
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
              height="100px"
              width="100px"
              className="fill-blue-600 group-hover:fill-blue-700 mx-auto"
            >
              <path d="M280-40q-33 0-56.5-23.5T200-120v-720q0-33 23.5-56.5T280-920h400q33 0 56.5 23.5T760-840v720q0 33-23.5 56.5T680-40H280Zm0-120v40h400v-40H280Zm0-80h400v-480H280v480Zm0-560h400v-40H280v40Zm0 0v-40 40Zm0 640v40-40Z" />
            </svg>
            <span className="text-3xl">0216 427 17 83</span>
          </NavLink>
          <NavLink
            to={"tel:02122660455"}
            className="group flex flex-col justify-around items-center w-full gap-5 my-3 border h-96 p-3 shadow-xl hover:shadow-2xl rounded-xl"
          >
            Hemen Bize Ulaşın
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
              height="100px"
              width="100px"
              className="fill-blue-600 group-hover:fill-blue-700 mx-auto"
            >
              <path d="M400-160h160v-40H400v40ZM280-40q-33 0-56.5-23.5T200-120v-720q0-33 23.5-56.5T280-920h400q33 0 56.5 23.5T760-840v720q0 33-23.5 56.5T680-40H280Zm0-200v120h400v-120H280Zm0-80h400v-400H280v400Zm0-480h400v-40H280v40Zm0 560v120-120Zm0-560v-40 40Z" />
            </svg>
            <span className="text-3xl">0212 266 04 55</span>
          </NavLink>
          <NavLink
            to={"tel:05323620016"}
            className="group flex flex-col justify-around items-center w-full gap-5 my-3 border h-96 p-3 shadow-xl hover:shadow-2xl rounded-xl"
          >
            Hemen Bize Ulaşın
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
              height="100px"
              width="100px"
              className="fill-blue-600 group-hover:fill-blue-700 mx-auto"
            >
              <path d="M280-40q-33 0-56.5-23.5T200-120v-720q0-33 23.5-56.5T280-920h400q33 0 56.5 23.5T760-840v720q0 33-23.5 56.5T680-40H280Zm0-200v120h400v-120H280Zm200 100q17 0 28.5-11.5T520-180q0-17-11.5-28.5T480-220q-17 0-28.5 11.5T440-180q0 17 11.5 28.5T480-140ZM280-320h400v-400H280v400Zm0-480h400v-40H280v40Zm0 560v120-120Zm0-560v-40 40Z" />
            </svg>
            <span className="text-3xl">0532 362 00 16</span>
          </NavLink>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-3 text-4xl text-center">
          <NavLink
            to={"whatsapp://send?phone=5323508851"}
            className="grid grid-rows-2 text-center gap-3 text-3xl p-3 border shadow-xl hover:shadow-2xl rounded-xl h-96"
          >
            Teklif ve bilgi almak için hemen tıklayın
            <svg
              aria-hidden="true"
              width="100px"
              height="100px"
              className="fill-blue-600 group-hover:fill-blue-700 mx-auto"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
            </svg>
            0532 362 00 16
          </NavLink>
          <NavLink
            to={"mailto:info@ozfilonakliyat.com"}
            className="grid grid-rows-2 text-center gap-3 text-3xl p-3 border shadow-xl hover:shadow-2xl rounded-xl h-96"
          >
            Hemen Mail Gönder
            <svg
              aria-hidden="true"
              width="100px"
              height="100px"
              className="fill-blue-600 group-hover:fill-blue-700 mx-auto"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
            </svg>
            info@ozfilonakliyat.com
          </NavLink>
        </div>
        <div className=" mx-auto mt-10 text-center">
          <h2 className="text-slate-700 text-6xl mb-2">Konumumuz</h2>
          <iframe
            className="mx-auto w-full h-96"
            loading="lazy"
            src="https://maps.google.com/ maps?q=Ba%C4%9Flarba%C5%9F%C4%B1%20Mahallesi%20Ba%C4%9Fdat%20Caddesi%20No%3A459&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
            title="Bağlarbaşı Mahallesi Bağdat Caddesi No:459"
            aria-label="Bağlarbaşı Mahallesi Bağdat Caddesi No:459"
          ></iframe>
        </div>
        <div className="flex justify-evenly my-20">
          <NavLink>
            <svg
              width="50px"
              height="50px"
              className=" fill-black hover:fill-blue-700"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
            </svg>
          </NavLink>
          <NavLink>
            <svg
              width="50px"
              height="50px"
              className=" fill-black hover:fill-blue-700"
              viewBox="0 0 576 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
            </svg>
          </NavLink>
          <NavLink>
            <svg
              width="50px"
              height="50px"
              className=" fill-black hover:fill-blue-700"
              viewBox="0 0 496 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"></path>
            </svg>
          </NavLink>
          <NavLink>
            <svg
              width="50px"
              height="50px"
              className=" fill-black hover:fill-blue-700"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
            </svg>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Iletisim;
